













import { Component, Vue } from "vue-property-decorator";

import { catVue } from "@/util/logging";
import store from "@/store";

// eslint-disable-next-line
      
   @Component({
     components: {},
   })
   export default class Bücher extends Vue {
     value = 0;

     created(): void {
       catVue.info(`${this.$options.name}:: CREATED ... ${this.value}`);
     }
   }

   //  store.commit("changeAppLoadingState", true, {root: true});
   //  window.setTimeout( () =>   store.commit("changeAppLoadingState", false, {root: true}), 10000)
