







































import Vue from "vue";

import store from "@/store";

import AuthModule from "@/store/modules/auth";
import { getModule } from "vuex-module-decorators";
const authState = getModule(AuthModule, store);

export default Vue.extend({
  name: "Login",

  data() {
    return {
      user: "",
      pass: "",
    };
  },

  methods: {
    doLogin(): void {
      console.log(authState);
      // console.log("Login ... 1 ... "+JSON.stringify(router.currentRoute.query,undefined,2));
      authState.login({ user: this.user, pass: this.pass });
    },
  },
});
